function format_string(str, values) {

    for (let i = 0; i < values.length; i++) {
        const element = values[i];
        let replace_value = "{i}".replace("i", i.toString());
        
        str = str.replace(replace_value, element)
        
    }

    return str
}
function zeroPadding(number, padding) {
    let response = number.toString()

    while(response.length< padding){
        response = "0"+ response
    }
    
    return response

}

function format_date(date) {
            
    date = new Date(date)
    let day = zeroPadding(date.getDate(), 2)
    let month = zeroPadding(date.getMonth()+1, 2)
    let year = zeroPadding(date.getFullYear(), 4)
    let hours = zeroPadding(date.getHours(), 2)
    let minutes = zeroPadding(date.getMinutes(), 2)

    return format_string("{0}/{1}/{2} {3}:{4}", [day, month, year, hours, minutes])

}

function get_element_or_undefined(param) {
    try {
        return document.getElementById(param).getContext('2d');
    } catch (error) {
        return undefined
    }
}

export default {
    format_string,
    zeroPadding,
    format_date,
    get_element_or_undefined
}