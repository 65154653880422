<template>
    <footer class="bdT ta-c p-10 lh-0 fsz-sm c-grey-600">
        <span v-if="config.serverUrl === 'chamados.maq-larem.com.br'">
            Copyright © 2023 Maq-larem - Impressão e Gestão Documental
            <b>BETA 2.0</b>
        </span>
        <span v-else>
            Copyright © 2023 Alfaprint
            <b>BETA 2.0</b>
        </span>
        <span style="display:none;">
            Copyright © 2017 Designed by
            <a href="https://colorlib.com" target="_blank" title="Colorlib">Colorlib</a>. All rights reserved.
        </span>
    </footer>
</template>


<script>
import config from "@/config"
export default {
    name: "CcFooter",
    data() {
        return {
            config
        }
    }
};
</script>
