<template>
    <div class="header navbar">
        <div class="header-container">
            <ul class="nav-left">
                <li>
                    <a id="sidebar-toggle" class="sidebar-toggle" href="javascript:void(0);">
                        <i class="ti-menu"></i>
                    </a>
                </li>
                <li class="search-box" v-if="!hideSearch">
                    <a class="search-toggle no-pdd-right" @click="search=!search">
                        <i class="search-icon ti-search pdd-right-10"></i>
                        <i class="search-icon-close ti-close pdd-right-10"></i>
                    </a>
                </li>
                <li v-bind:class="{'search-input':true, active:search }" v-if="!hideSearch">
                    <div style="display:inline-block">
                        <input
                            class="form-control"
                            type="text"
                            placeholder="Search..."
                            name="search"
                            v-model="search_text"
                            style="display:inline"
                        />
                    </div>
                    <div style="display:inline">
                        <a class="no-pdd-right" href="javascript:void(0)" @click="clearSearch()">
                            <i class="search-icon-close ti-close pdd-right-10"></i>
                        </a>
                    </div>
                </li>
            </ul>
            <ul class="nav-right">
                <li class="dropdown">
                    <a
                        href
                        class="dropdown-toggle no-after peers fxw-nw ai-c lh-1"
                        data-toggle="dropdown"
                    >
                        <div class="peer mR-10">
                            <img
                                class="w-2r bdrs-50p"
                                src="https://randomuser.me/api/portraits/men/10.jpg"
                                alt
                            />
                        </div>
                        <div class="peer">
                            <span class="fsz-sm c-grey-900">{{ user.first_name }}</span>
                        </div>
                    </a>
                    <ul class="dropdown-menu fsz-sm">
                        <li @click="logout" style="cursor:pointer">
                            <a class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                                <i class="ti-power-off mR-10"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
import AuthService from "@/services/auth";

export default {
    name: "CcHeader",
    props:{
        hideSearch:{
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
            search: false,
            search_text: "",
            user: {
                first_name: ""
            }
        };
    },
    methods: {
        logout() {
            AuthService.logout();
            this.$router.push("/login");
        },
        clearSearch() {
            this.search_text = "";
            this.search = false;
        }
    },
    created() {
        let user = AuthService.getUserInformation();
        this.user.first_name = user.name;
    },
    watch: {
        search_text: function() {
            this.$emit("search", this.search_text);
        }
    }
};
</script>
