<template>
  <div id="app">
    <CcSidebar></CcSidebar>
    <div class="page-container">
      <CcHeader :hide-search="true"></CcHeader>
      <main class="main-content bgc-grey-100">
        <div id="mainContent">
          <div class="full-container">
            <div class="email-app">
              <div class="row mR-0">
                <div class="col-md-12">
                  <div class="card m-10">
                    <div class="card-header">
                      <h3>Tabela de SLA</h3>
                    </div>
                    <div
                      class="card-body scrollable"
                      style="height: calc(85vh - 20px)"
                      @scroll="get_scroll"
                    >
                      <div>
                        <b-row>
                          <b-col>
                            <b-input-group class="mb-2">
                              <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                              </b-input-group-prepend>
                              <b-form-input
                                type="search"
                                placeholder="Busque por nome do cliente, nome do solicitante, matricula da maquina..."
                                v-model="searchParam"
                              ></b-form-input>
                            </b-input-group>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col
                                ><b-form-datepicker
                                locale="pt-BR"
                                  placeholder="Data inicial"
                                  labels="Data inicial"
                                  v-model="dateRange.start_date"
                                  hide-header="true"
                                ></b-form-datepicker
                              ></b-col> >>>
                              <b-col
                                ><b-form-datepicker
                                locale="pt-BR"
                                  placeholder="Data final"
                                  labels="Data final"
                                  v-model="dateRange.end_date"
                                  hide-header="true"
                                ></b-form-datepicker
                              ></b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Numero do chamado</th>
                            <th scope="col">Status</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Data de abertura</th>
                            <th scope="col">Data de conclusão</th>
                            <th scope="col">Tempo aberto</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="ticket in data" :key="ticket.code">
                            <th scope="row">{{ ticket.code }}</th>
                            <td>{{ statuses[ticket.status] }}</td>
                            <td>{{ ticket.client_obj.client }}</td>
                            <td>{{ ticket.created_at | format_date }}</td>
                            <td>
                              {{
                                (ticket.status == 3 ? ticket.updated_at : "")
                                  | format_date
                              }}
                            </td>
                            <td v-if="ticket.status > 1">
                              {{ calc_sla(ticket) }}
                            </td>
                            <td v-else :key="timestamp">
                              {{ calc_sla(ticket) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <CcFooter></CcFooter>
    </div>
  </div>
</template>

<script>
const PAGE_SIZE = 25;
import ticketService from "@/services/tickets_v2";
// import ticketService from "@/services/tickets";
import AuthService from "@/services/auth.js";
import { debounce } from "lodash";

import CcSidebar from "@/components/CcSidebar.vue";
import CcHeader from "@/components/CcHeader.vue";
import CcFooter from "@/components/CcFooter.vue";

export default {
  name: "CcMain",
  components: {
    CcSidebar,
    CcHeader,
    CcFooter,
  },
  data() {
    return {
      data: [],
      offset: 0,
      next: "",
      loading: false,
      statuses: {
        0: "Aberto",
        1: "Em Andamento",
        2: "Negado",
        3: "Concluido",
      },
      timestamp: 0,
      searchParam: "",
      dateRange: {},
    };
  },
  methods: {
    calc_sla(ticket) {
      let start_date = new Date(ticket.created_at);
      let end_date = new Date();
      if (ticket.status == 3) {
        end_date = new Date(ticket.updated_at);
      }
      let diff = end_date - start_date;

      let seconds = Math.floor(diff / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      seconds = seconds - minutes * 60;
      minutes = minutes - hours * 60;
      hours = hours - days * 24;

      return `${days ? ("00" + days).slice(-2) + "d" : ""}${(
        "00" + hours
      ).slice(-2)}h${("00" + minutes).slice(-2)}m${("00" + seconds).slice(
        -2
      )}s`;
    },
    get_scroll(e) {
      if (
        e.target.scrollHeight - e.target.offsetHeight <
        e.target.scrollTop + e.target.offsetHeight
      ) {
        this.get_next();
      }
    },
    get_next() {
      if (this.next) {
        let params = {};
        Array.from(new URL(this.next).searchParams).forEach((e) => {
          params[e[0]] = e[1];
        });
        this.next = undefined;
        ticketService.find(params, this.loadTicketsResp);
      }
    },
    loadTicketsResp(resp) {
      this.next = resp.next;
      resp.results.forEach((a) => {
        this.data.push(a);
      });
      this.loading = false;
    },
    filterTickets() {
      this.data = [];
      ticketService.find(
        {
          search: this.searchParam,
          created_at__date__gte: this.dateRange.start_date,
          created_at__date__lte:this.dateRange.end_date,
          limit: PAGE_SIZE,
        },
        this.loadTicketsResp
      );
    },
  },
  filters: {
    format_date(value) {
      if (!value) return "";
      let date = new Date(value);
      let day = `0${date.getDate()}`.slice(-2);
      let month = `0${date.getMonth() + 1}`.slice(-2);

      return `${day}/${month}/${date.getFullYear()}`;
    },
  },
  created() {
    let user_info = AuthService.getUserInformation();

    if (user_info.id == null) {
      this.$router.push("/login");
    }
    this.loading = true;
    ticketService.find({ limit: PAGE_SIZE }, this.loadTicketsResp);
    setInterval(() => {
      this.timestamp = new Date().getTime();
    }, 1000);
    this.debounced_filterTickets = debounce(this.filterTickets, 350);
  },
  watch: {
    searchParam: function() {
      this.debounced_filterTickets();
    },
    'dateRange.start_date': function() {
      this.debounced_filterTickets();
    },
    'dateRange.end_date': function() {
      this.debounced_filterTickets();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scrollable {
  overflow-y: scroll;
}
</style>
