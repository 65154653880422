<template>
<div id="app">
    <CcSidebar></CcSidebar>
    <div class="page-container">
        <CcHeader></CcHeader>
        <main class="main-content bgc-grey-100">
            <div id="mainContent">
                <div class="full-container">
                    <div class="email-app">
                        <div class="email-wrapper row remain-height bgc-white ov-h">
                            <div class="email-content h-100 scrollable" style="width: 100%;">
                                <div class="row mR-0">
                                    <div class="col-md-12">
                                        <div class="card m-10" style="height: calc(100% - 20px);">
                                            <div class="card-header">
                                                Adicionar Contador
                                            </div>
                                            <div class="card-body">
                                                <!-- Content -->
                                                <div class="p-10">
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Estado</label>
                                                            <select name="state" id="" class="form-control" v-model="ticket.state">
                                                                <option :value="state.code" v-for="state in states" :key="state.code">{{state.name}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-4">
                                                            <label>Matrícula</label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                </div>
                                                                <input type="text" name="registration" maxlength="100"
                                                                    class="form-control" required="" id="id_registration"
                                                                    v-model="ticket.registration">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Contador</label>
                                                            <input type="number" name="counter" class="form-control" id="id_counter"
                                                                v-model="ticket.counter">
                                                        </div>

                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-12">
                                                            <label>Nome do cliente</label>
                                                            <input type="text" name="client" maxlength="100" class="form-control"
                                                                required="" id="id_client" v-model="ticket.client">
                                                        </div>
                                                    </div>

                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Local ou Setor</label>
                                                            <input type="text" name="sector" maxlength="100" class="form-control"
                                                                id="id_sector" v-model="ticket.sector">
                                                        </div>
                                                    </div>
                                                    <!-- hidden auto inputs -->
                                                    <input type="email" name="email" value="suporte@alfaprint.com.br"
                                                        hidden>
                                                    <input type="text" name="name" value="Suporte" hidden>
                                                    <input type="text" name="phone" id="telefone" value="(83) 3133-4004"
                                                        hidden>
                                                    <input type="number" name="type" value="2" hidden>
                                                    <input type="hidden" name="status" value="1">

                                                    <div class="peer mt-1">
                                                        <button name="status" class="btn btn-success p-15 lh-0" @click="submitTicket()">Submeter</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <CcFooter></CcFooter>
    </div>
</div>
</template>

<script>
import AuthService from '@/services/auth.js'
import ticketService from '@/services/tickets'
import statesService from '@/services/states'

import CcSidebar from '@/components/CcSidebar.vue'
import CcHeader from '@/components/CcHeader.vue'
import CcFooter from '@/components/CcFooter.vue'

import utils from '@/services/utils.js'
import swal from 'sweetalert'

export default {
    name: 'CcMain',
    components: {
        CcSidebar,
        CcHeader,
        CcFooter,
    },
    data() {
        return {
            "states": [],
            "ticket": {
                email:"suporte@alfaprint.com.br",
                name:"Suporte",
                phone:"(83) 3133-4004",
                type:2,
                status:1

            },
        }
    },
    methods: {
        fetchData() {
            statesService.all(this.addStatesToScope)
        },
        addStatesToScope(data) {
            this.states = []
            data.forEach((state) => {
                this.states.push(state)
            });
        },
        submitTicket(){
            ticketService.support_create(this.ticket, this._callback)
        },
        _callback(resp) {
            if (resp.error == false) {
                swal({
                    title:"Sucesso",
                    text:"Chamado adicionado! Deseja adicionar mais leituras?",
                    icon:"success",
                    buttons: {
                        confirm:{
                            text:"Deseja adicionar mais leituras?",
                            value:true,
                            className:"btn btn-success"
                        },
                        cancel:{
                            visible:true,
                            text:"Voltar a pagina principal",
                            className: "btn btn-danger"
                        }
                    }    
                }).then((value) => {
                    if (value) {
                        this.ticket = {
                            email:"suporte@alfaprint.com.br",
                            name:"Suporte",
                            phone:"(83) 3133-4004",
                            type:2,
                            status:1
                        }
                    }else{
                        this.$router.push("/")
                    }
                })
            } else {
                swal("Oops", "Algo deu errado!"+resp.message, "error")
            }
        }
    },
    created() {
        let user_info = AuthService.getUserInformation()

        if (user_info.id == null) {
            this.$router.push('/login')
        } else {
            this.fetchData()
        }
    },
    filters: {
        date: utils.format_date
    },
    watch: {
        params: {
            handler: function () {
                ticketService.query(this.params, this.addTicketsToScope)
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#tickets_list_div{
    overflow-y: scroll !important
}
</style>
