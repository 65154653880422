<template>
<div class="peers ai-s fxw-nw h-100vh">
        <div class="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv" style='background-image: url("assets/static/images/bg.jpg")'>
            <div class="pos-a centerXY">
                <div class="bgc-white bdrs-50p pos-r" style='width: 120px; height: 120px;'>
                    <img class="pos-a centerXY" src="@/assets/img/banco-de-talentos.png" alt="">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r" style='min-width: 320px;'>
            <h4 class="fw-300 c-grey-900 mB-40">Login</h4>
            <form @submit="doAuth()" onsubmit="return false">
                <div class="form-group">
                    <label class="text-normal text-dark">Username</label>
                    <input name="username" type="text" class="form-control" placeholder="Usuário" v-model="user.username">
                </div>
                <div class="form-group">
                    <label class="text-normal text-dark">Password</label>
                    <input name="password" type="password" class="form-control" placeholder="Senha" v-model="user.password">
                </div>
                <div class="form-group">
                    <div class="peers ai-c jc-sb fxw-nw">
                        <div class="peer">
                            <button class="btn btn-primary">Login</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>


<script>
import swal from 'sweetalert'
import auth from '@/services/auth'

export default {
    data() {
        return {
            user: {
                username: "",
                password: ""
            }
        }
    },
    methods:{
        doAuth(){
            auth.login(this.user, this.loggedIn)
        },
        loggedIn(success) {
            if(success){
                this.$router.push('/')
            }else{
                swal({
                    title:"Falha no login",
                    text:"Usuario ou senha incorretos",
                    icon:"error",
                    button: "Ok" 
                })
            }
        }
    }
}
</script>
