import config from "@/config.js"
import axios from 'axios'

const LOCAL_NAME_KEY = 'UserName';
const LOCAL_ID_KEY = 'UserID';
const LOCAL_TOKEN_KEY = 'UserToken';
const LOCAL_IS_STAFF_KEY = 'UserIsStaff';

class Auth {

    constructor() {
        this.url = "https://{0}:{1}/{2}/".replace("{0}", config.serverUrl).replace("{1}", config.serverPort).replace("{2}", "api/login");
        this.isAuthenticated = false
        this.authToken = null;
        axios.defaults.headers.common['Authorization'] = undefined;
        this.loadUserCredentials()
    }

    loadUserCredentials() {
        var sessionToken = window.sessionStorage.getItem(LOCAL_TOKEN_KEY);
        if (sessionToken) {
            this.useCredentials(sessionToken);
        } else {
            var localToken = window.sessionStorage.getItem(LOCAL_TOKEN_KEY);
            if (localToken) {
                this.useCredentials(localToken);
            }
        }
    }


    login(data, callback) {
        axios.post(this.url, {
            username: data.username,
            password: data.password
        }).then((resp) => {
            var formattedToken = `Token ${resp.data.token}`
            this.storeUserInformation(resp.data);

            this.useCredentials(formattedToken);

            callback(true)
        }).catch((err) => {
            console.log(err);
            callback(false)
        })
    }

    _checkBeforeStore(key, value) {
        // AVOID SET NULL VALUE AS STRING 'NULL'
        if (value) {
            window.sessionStorage.setItem(key, value);
        }
    }

    getUserInformation() {
        return {
            name: window.sessionStorage.getItem(LOCAL_NAME_KEY),
            id: window.sessionStorage.getItem(LOCAL_ID_KEY),
            is_staff: window.sessionStorage.getItem(LOCAL_IS_STAFF_KEY),
        }
    }

    useCredentials(token) {
        this.isAuthenticated = true;
        this.authToken = token;

        window.sessionStorage.setItem(LOCAL_TOKEN_KEY, token);

        //Set the token as header
        axios.defaults.headers.common['Authorization'] = token;
    }

    storeUserInformation(user) {
        this._checkBeforeStore(LOCAL_NAME_KEY, user.name);
        this._checkBeforeStore(LOCAL_ID_KEY, user.id);
        this._checkBeforeStore(LOCAL_IS_STAFF_KEY, user.is_staff);
    }

    logout() {
        this.authToken = undefined;
        this.isAuthenticated = false;
        axios.defaults.headers.common['Authorization'] = undefined;
        window.sessionStorage.removeItem(LOCAL_TOKEN_KEY);
        window.sessionStorage.removeItem(LOCAL_NAME_KEY);
        window.sessionStorage.removeItem(LOCAL_ID_KEY);
    }
}

export default new Auth()

export {
    LOCAL_NAME_KEY,
    LOCAL_ID_KEY
}