<template>
    <div id="app">
        <CcSidebar></CcSidebar>
        <div class="page-container">
            <CcHeader @search="search"></CcHeader>
            <main class="main-content bgc-grey-100">
                <div id="mainContent">
                    <div class="full-container">
                        <div class="email-app">
                            <div class="email-wrapper row remain-height bgc-white ov-h">
                                <div class="email-list h-100 layers">
                                    <div class="layer w-100">
                                        <div class="bgc-grey-100 peers ai-c jc-sb p-20 fxw-nw">
                                            <div class="peer">
                                                <div class="btn-group" role="group">
                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.group_by == 0 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('group_by', 0)"
                                                    >
                                                        <i class="ti-hummer"></i>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.group_by == 1 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('group_by', 1)"
                                                    >
                                                        <i class="ti-paint-bucket"></i>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.group_by == 2 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('group_by', 2)"
                                                    >
                                                        <i class="ti-info"></i>
                                                    </button>


                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.group_by == 3 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('group_by', 3)"
                                                    >
                                                        <i class="ti-desktop"></i>
                                                    </button>


                                                    <button
                                                        type="button"
                                                        class="btn bgc-white bdrs-2 mR-3 cur-p"
                                                        v-on:click="toggleParams('group_by', -1)"
                                                    >Todos</button>
                                                </div>
                                            </div>
                                            <div class="peer">
                                                <div class="btn-group" role="group">
                                                    <div v-for="state in states" :key="state.code">
                                                        <button
                                                            type="button"
                                                            class="btn bdrs-2 mR-3 cur-p"
                                                            v-on:click="toggleParams('state', state.code)"
                                                            v-bind:class="[state.code == params.state ? 'bgc-grey-300' : 'bgc-white']"
                                                        >{{state.code}}</button>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        class="btn bgc-white bdrs-2 mR-3 cur-p"
                                                        v-on:click="toggleParams('state', -1)"
                                                    >Todos</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="layer w-100 fxg-1 scrollable pos-r"
                                        id="tickets_list_div"
                                    >
                                        <div class>
                                            <div v-if="loading.tickets == false">
                                                <div
                                                    class="email-list-item peers fxw-nw p-20 bdB bgcH-grey-100 cur-p"
                                                    v-on:click="open_if_not_already_open(ticket.id)"
                                                    v-for="ticket in tickets"
                                                    :key="ticket.id"
                                                >
                                                    <div class="peer peer-greed ov-h">
                                                        <div class="peers ai-c">
                                                            <div class="peer peer-greed">
                                                                <span
                                                                    class="badge badge-pill badge-primary lh-0 p-10"
                                                                    v-if="ticket.type == 0"
                                                                >man</span>
    
                                                                <span
                                                                    class="badge badge-pill badge-warning lh-0 p-10"
                                                                    v-else-if="ticket.type == 1"
                                                                >sup</span>
    
                                                                <span
                                                                    class="badge badge-pill badge-secondary lh-0 p-10"
                                                                    v-else-if="ticket.type == 2"
                                                                >ler</span>
    
    
                                                                <span
                                                                    class="badge badge-pill badge-info lh-0 p-10"
                                                                    v-else-if="ticket.type == 3"
                                                                >sol</span>
                                                                <div v-if="ticket.client_obj">
                                                                    <span
                                                                        class="fsz-def c-grey-900 mt-2"
                                                                    >
                                                                        <b>{{ ticket.client_obj.client }}</b>
                                                                        <small class="c-grey-700">
                                                                            ({{ticket.code}}) por
                                                                            {{ ticket.client_obj.name }}
                                                                        </small>
                                                                    </span>
                                                                </div>
                                                                <!-- add fallback to client_obj -->
                                                                <div v-else>
                                                                    <span
                                                                        class="fsz-def c-grey-900 mt-2"
                                                                    >
                                                                        <b>{{ ticket.client }}</b>
                                                                        <small class="c-grey-700">
                                                                            ({{ticket.code}}) por
                                                                            {{ ticket.name }}
                                                                        </small>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="peer">
                                                                <small>{{ ticket.created_at| date}}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-100 mt-1" style="text-align:center">
                                                <div v-if="loading.tickets">Carregando...</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CcTicket :initialTicket="ticket" @refetch="fetchData()"></CcTicket>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <CcFooter></CcFooter>
        </div>
    </div>
</template>

<script>
import technicianService from "@/services/technicians.js";
import AuthService from "@/services/auth.js";
import ticketService from "@/services/tickets";
import statesService from "@/services/states";

import CcSidebar from "@/components/CcSidebar.vue";
import CcHeader from "@/components/CcHeader.vue";
import CcFooter from "@/components/CcFooter.vue";
import CcTicket from "@/components/CcTicket.vue";

import utils from "@/services/utils.js";

export default {
    name: "CcMain",
    components: {
        CcSidebar,
        CcHeader,
        CcFooter,
        CcTicket
    },
    data() {
        return {
            states: [],
            selected_state: "",
            form: {
                obs: ""
            },
            ticket: false,
            tickets: [],
            technicians: [
                {
                    name: "",
                    pk: ""
                }
            ],
            params: {
                test: 1
            },
            loading: {
                tickets: true
            }
        };
    },
    methods: {
        fetchData() {
            technicianService.all(this.addTechniciansToScope);
            ticketService.query(this.params, this.addTicketsToScope);
            statesService.all(this.addStatesToScope);
        },
        addTechniciansToScope(data) {
            this.technicians = [];
            data.forEach(technician => {
                this.technicians.push({
                    name: "{0} ({1})"
                        .replace("{0}", technician.name)
                        .replace("{1}", technician.count),
                    pk: technician.id
                });
            });
        },
        addTicketsToScope(data) {
            this.tickets = [];

            data.data.tickets.forEach(ticket => {
                this.tickets.push(ticket);
            });

            this.ticket = this.tickets[0];
            this.loading.tickets = false;
        },
        addStatesToScope(data) {
            this.states = [];
            data.forEach(state => {
                this.states.push(state);
            });
        },
        toggleParams(key, value) {
            if (value == -1) {
                this.$delete(this.params, key);
            } else {
                this.$set(this.params, key, value);
            }
        },
        open_if_not_already_open(ticket_id) {
            this.tickets.forEach(ticket => {
                if (ticket.id == ticket_id) {
                    this.ticket = ticket;
                }
            });
        },
        search(data) {
            this.loading.tickets = true;
            this.params.search = data;
            if (this.params.search == "") {
                delete this.params.search;
            }
            ticketService.query(this.params, this.addTicketsToScope);
        }
    },
    created() {
        let user_info = AuthService.getUserInformation();

        if (user_info.id == null) {
            this.$router.push("/login");
        } else {
            this.fetchData();
        }
    },
    filters: {
        date: utils.format_date
    },
    watch: {
        params: {
            handler: function() {
                this.loading.tickets = true;
                ticketService.query(this.params, this.addTicketsToScope);
            },
            deep: true
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
    font-weight: normal;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
#tickets_list_div {
    overflow-y: scroll !important;
}
</style>
