import generic from "@/services/generic_api.js";
import axios from "axios";

let data_tickets = new generic("api/data_tickets");
let url = data_tickets.url;

data_tickets.get_data = function (month, year, callback) {
    axios.get(url, {
        params: {
            month: month,
            year: year
        }
    }).then((resp) => {
        callback(resp.data);
    }).catch((err) => {
        console.log(err);
    });
};

export default data_tickets;