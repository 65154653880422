<template>
  <div id="app">
    <CcSidebar></CcSidebar>
    <div class="page-container">
      <CcHeader></CcHeader>
      <main class="main-content bgc-grey-100">
        <div id="mainContent">
          <div class="full-container">
            <div class="email-app">
              <div class="row mR-0">
                <div class="col-md-9">
                  <div class="card m-10">
                    <div class="card-header">
                      Auditoria <small>(--> significa "alterado para")</small>
                    </div>
                    <div
                      class="card-body scrollable"
                      style="height: calc( 85vh - 20px);"
                      @scroll="get_scroll"
                    >
                      <div
                        v-for="d in data"
                        :key="d.id"
                        style="border-bottom:1px solid #F0F0F0;"
                        class="mt-1 mb-1"
                      >
                        <div class="row">
                          <div class="col-1">#{{ d.id }}</div>
                          <div class="col-11">
                            <span :style="{color:format_actor_name(d.actor) == 'O sistema' ?'red' : 'blue'}">
                              {{ format_actor_name(d.actor) }}
                            </span>
                            
                            {{ actions[d.action] }} o chamado
                            {{ d.ticket ? d.ticket.code : "" }}
                            <div v-if="d.action == 0">
                              <ul>
                                <li
                                  v-for="(c, index) in d.changes"
                                  :key="c[1]"
                                  v-show="show_value(index, c[1], d.ticket)"
                                >
                                  {{ columns[index] ? columns[index] : index }}:
                                  {{ format_value(index, c[1], d.ticket) }}
                                </li>
                              </ul>
                            </div>
                            <div v-else-if="d.action == 1">
                              <ul>
                                <li
                                  v-for="(c, index) in d.changes"
                                  :key="c[1]"
                                  v-show="show_value(index, c[1], d.ticket)"
                                >
                                  {{ columns[index] ? columns[index] : index }}:
                                  {{
                                    c[0] != "None"
                                      ? format_value(index, c[0], d.ticket)
                                      : ""
                                  }}
                                  -->
                                  {{ format_value(index, c[1], d.ticket) }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <CcFooter></CcFooter>
    </div>
  </div>
</template>

<script>
import ticketService from "@/services/tickets";
import AuthService from "@/services/auth.js";
import AuditService from "@/services/audit.js";
import UsersService from "@/services/users.js";

import CcSidebar from "@/components/CcSidebar.vue";
import CcHeader from "@/components/CcHeader.vue";
import CcFooter from "@/components/CcFooter.vue";

export default {
  name: "CcMain",
  components: {
    CcSidebar,
    CcHeader,
    CcFooter
  },
  data() {
    return {
      next: "",
      columns: {
        id: "Identificador Unico no banco de dados",
        created_at: "Criado em",
        updated_at: "Atualizado em",
        type: "Tipo do chamado",
        prefered_time: "Período ideal",
        description: "Descrição",
        code: "Código",
        code_databit: "Código no databit",
        registration: "Matricula",
        counter: "Contador",
        phone: "Telefone",
        sector: "Setor",
        client_obj: "Solicitante",
        client: "Cliente",
        obs: "OBS Suporte",
        verified: "Verificado",
        status: "Status",
        test: "Chamado de teste",
        address: "Endereço",
        state: "Estado",
        model: "Modelo"
      },
      statuses: {
        0: "Aberto",
        1: "Em Andamento",
        2: "Negado",
        3: "Concluido"
      },
      actions: {
        0: "criou",
        1: "atualizou",
        2: "removeu"
      },

      type: {
        0: "Manutenção",
        1: "Recarga",
        2: "Leitura",
        3: "Soluções"
      },

      data: [],
    };
  },
  methods: {
    format_value(column, value) {
      let f_value = value;
      let d = new Date(value)

      switch (column) {
        case "type":
          f_value = this.type[value];
          break;
        case "status":
          f_value = this.statuses[value];
          break;
        case "test":
          f_value = value == "True" ? "Sim" : "Não";
          break;
        case "verified":
          f_value = value == "True" ? "Sim" : "Não";
          break;
        case "created_at":
        case "updated_at":
          f_value = `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
          break;
        default:
          break;
      }

      return f_value;
    },
    show_value(column, value, item) {
      if (!item) {
        return false;
      }
      let b_value = Boolean(value);

      switch (column) {
        case "toner_color":
          b_value = !(item.type != 1 || value < 0);
          break;
        case "toner_obj":
          b_value = !(String(value.indexOf("None")) > -1);
          break;
        case "name":
          b_value = false;
          break;
        default:
          break;
      }

      return b_value;
    },
    format_actor_name(actor) {
      let actor_name =
        actor && actor.id
          ? `${actor.first_name} ${actor.last_name}`
          : "O sistema";
      return actor_name.replaceAll(" ", "") ? actor_name : actor.username;
    },
    get_scroll(e) {
      if (
        e.target.scrollHeight - e.target.offsetHeight <
        e.target.scrollTop + e.target.offsetHeight
      ) {
        this.get_next();
      }
    },
    get_next() {
      if (this.next) {
          let params = {}
        Array.from(new URL(this.next).searchParams).forEach(e => {
          params[e[0]] = e[1];
        });
        this.next = undefined;
        AuditService.find(params, this.loadAuditResp)
      }
    },
    loadAuditResp(resp){
        resp.results.forEach(a => {
            this.data.push(a)
        })
      this.next = resp.next ? resp.next : "";
      resp.results.forEach(element => {
        element.changes = JSON.parse(element.changes);
        if (element.actor) {
          UsersService.get(element.actor, resp => {
            this.$set(element, "actor", resp);
          });
        }
        ticketService.get(element.object_pk, resp => {
          this.$set(element, "ticket", resp.data);
        });
      });
    }
  },
  created() {
    let user_info = AuthService.getUserInformation();

    if (user_info.id == null) {
      this.$router.push("/login");
    }
    AuditService.all(this.loadAuditResp);
  },
  filters: {},
  watch: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scrollable {
  overflow-y: scroll;
}
</style>
