import generic from "@/services/generic_api.js";
import axios from 'axios'
import utils from '@/services/utils'

let tickets_api = new generic("api/tickets")

tickets_api.request_count = 0;
tickets_api.actual_response = 0

tickets_api.query = function (data, callback) {
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            if (typeof (element) == typeof ([])) {
                data[key] = ",".concat(element).slice(1)
            }
        }
    }

    tickets_api.request_count++
    data.count = tickets_api.request_count

    axios.get(this.url, { params: data }).then((resp) => {
        if (resp.data.data.count > tickets_api.actual_response) {
            tickets_api.actual_response = Number(resp.data.data.count)
            callback(resp.data)
        }
    }).catch((err) => {
        console.log(err)
    })
}

tickets_api.update = function (data, callback) {
    let url = utils.format_string(this.url + "{0}/", [data.id])
    axios.put(url, data).then((resp) => {
        callback(resp.data)
    }).catch((err) => {
        console.log(err)
    })
}

tickets_api.dashboard = function (callback) {
    let url = utils.format_string(this.url + "{0}/", ['dashboard'])
    axios.get(url).then((resp) => {
        callback(resp.data)
    }).catch((err) => {
        console.log(err)
    })
}

tickets_api.support_create = function (data, callback) {
    let url = utils.format_string(this.url + "{0}/", ['support_create'])
    axios.post(url, data).then((resp) => {
        callback(resp.data)
    }).catch((err) => {
        console.log(err)
    })
}

tickets_api.count = function (data, callback) {
    let url = utils.format_string(this.url + "{0}/", ['count'])

    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            if (typeof (element) == typeof ([])) {
                data[key] = ",".concat(element).slice(1)
            }
        }
    }

    delete data.limit

    axios.get(url, { params: data }).then((resp) => {
        callback(resp.data)
    }).catch((err) => {
        console.log(err)
    })
}


export default tickets_api