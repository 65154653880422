<template>
  <div class="email-content h-100" v-if="ticket" id="ticket_container">
    <div class="h-100 scrollable pos-r">
      <div class="email-content-wrapper">
        <div>
          <!-- Header -->
          <div class="peers ai-c jc-sb p-10">
            <div class="peers peer-greed">
              <div class="peer w-100">
                <span
                  class="badge badge-pill badge-primary lh-0 p-10"
                  v-if="ticket.type == 0"
                  >manutenção</span
                >

                <span
                  class="badge badge-pill badge-warning lh-0 p-10"
                  v-else-if="ticket.type == 1"
                  >suprimento</span
                >

                <span class="badge badge-pill badge-secondary lh-0 p-10" v-else-if="ticket.type == 2"
                  >leitura</span
                >

                <span class="badge badge-pill badge-info lh-0 p-10" v-else-if="ticket.type == 3"
                  >Solução</span
                >
                <span
                  >numero do chamado: {{ ticket.code }} numero do chamado no
                  databit:
                  {{ ticket.code_databit | default_if_none("Nenhum") }}</span
                >
                <small class="fl-r">{{ ticket.created_at | date }}</small>

                <div v-if="ticket.client_obj">
                  <h5 class="c-grey-900 mB-5 mt-2">
                    {{ ticket.client_obj.client }}
                  </h5>
                  <p class="c-grey-900 mB-2">
                    <span>Solicitante: {{ ticket.client_obj.name }}</span>
                  </p>
                </div>
                <!-- fallback to ticket.client_obj -->
                <div v-else>
                  <h5 class="c-grey-900 mB-5 mt-2">{{ ticket.client }}</h5>
                  <p class="c-grey-900 mB-2">
                    <span>Solicitante: {{ ticket.name }}</span>
                  </p>
                </div>

                <p class="c-grey-900 mB-2" v-if="ticket.type == 3">
                  Solução: {{ ticket.registration }}
                </p>
                <p class="c-grey-900 mB-2">
                  Descrição do problema: {{ ticket.description }}
                </p>
                <template
                v-if="ticket.toner_colors != {}"
                >
                  <p
                    class="c-grey-900 mB-2"
                    v-for="color in ticket.toner_colors"
                    v-bind="color"
                    :key="color.color"
                  >
                    Cor do toner: {{ color.color }} : {{ color.qtd }}
                  </p>
                </template>

                <!-- <p class="c-grey-900 mB-2" v-if="ticket.client_obj">
                  E-mail:{{ ticket.client_obj.email }}
                  <span style="margin-left:10rem"
                    >Telefone: {{ ticket.client_obj.phone }}</span
                  >
                </p> -->
                <!-- fallback to ticket.client_obj -->
                <!-- <p class="c-grey-900 mB-2" v-else>
                  E-mail:{{ ticket.email }}
                  <span style="margin-left:10rem"
                    >Telefone: {{ ticket.phone }}</span
                  >
                </p> -->
                <p class="c-grey-900 mB-2" v-if="!(ticket.phone) && !(ticket.email)">
                  E-mail:{{ ticket.client_obj.email }}
                  <span style="margin-left:10rem"
                    >Telefone: {{ ticket.client_obj.phone }}</span
                  >
                </p>
                <p class="c-grey-900 mB-2" v-else-if="!(ticket.email)">
                  E-mail:{{ ticket.client_obj.email }}
                  <span style="margin-left:10rem"
                    >Telefone: {{ ticket.phone }}</span
                  >
                </p>
                <p class="c-grey-900 mB-2" v-else-if="!(ticket.phone)">
                  E-mail:{{ ticket.email }}
                  <span style="margin-left:10rem"
                    >Telefone: {{ ticket.client_obj.phone }}</span
                  >
                </p>
                <p class="c-grey-900 mB-2" v-else>
                  E-mail:{{ ticket.email }}
                  <span style="margin-left:10rem"
                    >Telefone: {{ ticket.phone }}</span
                  >
                </p>
                <p class="c-grey-900 mB-2">
                  Período ideal: {{ ticket.prefered_time }}
                </p>
              </div>
            </div>
          </div>

          <!-- Content -->
          <div class="bdT p-10">
            <div class="form-row" v-if="ticket.type != 3">
              <div class="form-group col-md-4">
                <label>Matrícula</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="basic-addon1"
                      style="background-color: #4caf50; color:white"
                      v-if="ticket.verified"
                    >
                      <i class="ti-check"></i>
                    </span>
                    <span
                      class="input-group-text"
                      id="basic-addon1"
                      style="background-color: #f44336; color:white"
                      v-else
                    >
                      <i class="ti-close"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="registration"
                    v-model="ticket.registration"
                    maxlength="100"
                    class="form-control"
                    required
                    id="id_registration"
                  />
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Modelo</label>
                <input
                  type="text"
                  name="model"
                  v-model="ticket.model"
                  maxlength="100"
                  class="form-control"
                  id="id_model"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Contador</label>
                <input
                  type="number"
                  name="counter"
                  v-model="ticket.counter"
                  class="form-control"
                  id="id_counter"
                />
              </div>
            </div>
            <div v-else class="form-row">
              <div class="form-group col-md-12">
                <label>Solução</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="registration"
                    v-model="ticket.registration"
                    maxlength="100"
                    class="form-control"
                    required
                    id="id_registration"
                  />
                </div>
              </div>

            </div>
            <div class="form-group">
              <label>Setor</label>
              <input
                type="text"
                name="sector"
                v-model="ticket.sector"
                maxlength="100"
                class="form-control"
                id="id_sector"
              />
            </div>
            <div class="form-group">
              <label>Endereço</label>
              <textarea
                name="address"
                cols="40"
                rows="1"
                class="form-control"
                id="id_address"
                v-model="ticket.address"
              ></textarea>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <div>
                  <label>Código databit</label>
                  <input
                    type="text"
                    name="sector"
                    v-model="ticket.code_databit"
                    maxlength="100"
                    class="form-control"
                    id="id_sector"
                  />
                </div>
                <div>
                  <label>Técnico</label>
                  <select
                    name="technician"
                    class="form-control"
                    id="id_technician"
                    v-model="ticket.technician"
                  >
                    <option value>----------------</option>
                    <option
                      :value="technician.pk"
                      v-for="technician in technicians"
                      :key="technician.pk"
                      >{{ technician.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="form-group col-md-8">
                <label>OBS Suporte</label>
                <textarea
                  name="obs"
                  cols="40"
                  rows="4"
                  class="form-control"
                  id="id_obs"
                  v-model="ticket.obs"
                ></textarea>
              </div>
            </div>
            <div style="display: flex">
              <div class="peer" v-if="ticket.status == 0" id="buttons">
                <button
                  type="submit"
                  name="status"
                  class="btn btn-danger p-15 lh-0 m-5"
                  value="2"
                  @click="submitTicket(ticket, 2)"
                >
                  Negar
                </button>
                <button
                  type="submit"
                  name="status"
                  class="btn btn-success p-15 lh-0 m-5"
                  value="1"
                  @click="submitTicket(ticket, 1)"
                >
                  Aprovar
                </button>
              </div>
              <div class="peer" v-if="ticket.status == 1" id="buttons">
                <button
                  name="status"
                  class="btn btn-success p-15 lh-0 m-5"
                  value="3"
                  @click="submitTicket(ticket, 3)"
                >
                  Concluir
                </button>
                <button
                  name="status"
                  class="btn btn-success p-15 lh-0 m-5"
                  value="1"
                  @click="submitTicket(ticket, 1)"
                >
                  Atualizar
                </button>
              </div>
              <div class="peer" style="margin-top: 0.5rem !important">
                <select class="btn btn-success m-5" v-model="ticket.type" @change="submitTicket(ticket, ticket.status)">
                  <option value="0">Manutenção</option>
                  <option value="1">Recarga</option>
                  <option value="2">Leitura</option>
                  <option value="3">Soluções</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import technicianService from "@/services/technicians.js";
import utils from "@/services/utils.js";

import ticketService from "@/services/tickets";

export default {
  name: "CcTicket",
  props: ["initialTicket"],
  filters: {
    date: utils.format_date,
    default_if_none: function(value, arg) {
      if (!value) {
        return arg;
      }
      return value;
    }
  },
  data() {
    return {
      technicians: [],
      ticket: Object.assign({}, this.initialTicket)
    };
  },
  methods: {
    submitTicket: function(ticket, status) {
      ticket.status = status;
      ticketService.update(ticket, this.callback);
    },
    callback: function() {
      this.ticket = undefined;
      this.$emit("refetch");
    },
    fetchData: function() {
      technicianService.all(this.addTechniciansToScope);
    },
    addTechniciansToScope(data) {
      this.technicians = [];
      data.forEach(technician => {
        this.technicians.push({
          name: "{0} ({1})"
            .replace("{0}", technician.name)
            .replace("{1}", technician.count),
          pk: technician.id
        });
      });
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    initialTicket: function() {
      this.ticket = Object.assign({}, this.initialTicket);
    }
  }
};
</script>

<style scoped>
#ticket_container {
  overflow: scroll;
}
#buttons {
  margin-top: 0.5rem !important;
}

.form-group {
  margin-bottom: 0px !important;
}
</style>
