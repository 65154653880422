<template>
  <!-- Sidebar -->
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-logo">
        <div class="peers ai-c fxw-nw">
          <div class="peer peer-greed">
            <a class="sidebar-link td-n" href="index.html">
              <div
                class="peers ai-c fxw-nw"
                v-if="config.serverUrl === 'chamados.maq-larem.com.br'"
              >
                <div class="peer">
                  <div class="logo p-5">
                    <img src="@/assets/img/logo-icon.png" alt />
                  </div>
                </div>
                <div class="peer peer-greed">
                  <div class="logo">
                    <img src="@/assets/img/logo-name.png" alt />
                  </div>
                </div>
              </div>
              <div class="peers ai-c fxw-nw" v-else>
                <div class="logo">
                  <img
                    src="@/assets/img/icon-alphaprint.jpg"
                    height="84"
                    width="200"
                    alt
                  />
                </div>
              </div>
            </a>
          </div>
          <div class="peer">
            <div class="mobile-toggle sidebar-toggle">
              <a href class="td-n">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ul class="sidebar-menu scrollable pos-r">
        <li class="nav-item mT-30 active">
          <router-link class="sidebar-link" :to="{ name: 'CcDashboard' }">
            <span class="icon-holder">
              <i
                :class="{
                  'ti-home': true,
                  'c-red-500': config.serverUrl === 'chamados.maq-larem.com.br',
                  'c-blue-500':
                    config.serverUrl !== 'chamados.maq-larem.com.br',
                }"
              ></i>
            </span>
            <span class="title">Dashboard</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="user.is_staff">
          <router-link class="sidebar-link" :to="{ name: 'CcSLA' }">
            <span class="icon-holder">
              <i class="ti-view-list" :class="getIconsColor()"></i>
            </span>
            <span class="title">Tabela de SLA</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="user.is_staff">
          <router-link class="sidebar-link" :to="{ name: 'CcAudit' }">
            <span class="icon-holder">
              <i class="ti-list" :class="getIconsColor()"></i>
            </span>
            <span class="title">Auditoria</span>
          </router-link>
        </li>
        <li class="nav-item">
          <span
            class="badge badge-pill fl-r badge-danger lh-0 p-10 mt-2 mr-1"
            v-show="open_count > 0"
            >{{ open_count }}</span
          >
          <router-link
            class="sidebar-link"
            v-bind:class="{ active: active_tickets_list }"
            :to="{ name: 'CcMain' }"
          >
            <span class="icon-holder">
              <i class="ti-headphone-alt" :class="getIconsColor()"></i>
            </span>
            <span class="title">Chamados</span>
          </router-link>
        </li>
        <li class="nav-item">
          <span
            class="badge badge-pill fl-r badge-danger lh-0 p-10 mt-2 mr-1"
            v-show="ongoing_count > 0"
            >{{ ongoing_count }}</span
          >
          <router-link
            class="sidebar-link"
            v-bind:class="{ active: active_tickets_ongoing }"
            :to="{ name: 'CcOngoing' }"
          >
            <span class="icon-holder">
              <i class="ti-receipt" :class="getIconsColor()"></i>
            </span>
            <span class="title">Chamados em aberto</span>
          </router-link>
        </li>
        <li class="nav-item">
          <span
            class="badge badge-pill fl-r badge-danger lh-0 p-10 mt-2 mr-1"
            v-show="test_count > 0"
            >{{ test_count }}</span
          >
          <router-link
            class="sidebar-link"
            v-bind:class="{ active: active_tickets_test }"
            :to="{ name: 'CcTest' }"
          >
            <span class="icon-holder">
              <i class="ti-alert" :class="getIconsColor()"></i>
            </span>
            <span class="title">Chamados de teste</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="sidebar-link"
            v-bind:class="{ active: active_tickets_history }"
            :to="{ name: 'CcHistory' }"
          >
            <span class="icon-holder">
              <i class="ti-archive" :class="getIconsColor()"></i>
            </span>
            <span class="title">Histórico de chamados</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" :to="{ name: 'CcNewTicket' }">
            <span class="icon-holder">
              <i class="ti-plus" :class="getIconsColor()"></i>
            </span>
            <span class="title">Adicionar contador</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a class="sidebar-link" :href="`http://${config.serverUrl}/tickets/web-create/ticket`">
            <span class="icon-holder">
              <i class="ti-plus" :class="getIconsColor()"></i>
            </span>
            <span class="title">Abrir chamado técnico</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="sidebar-link"
            :href="`http://${config.serverUrl}/tickets/web-create/supply`"
          >
            <span class="icon-holder">
              <i class="ti-plus" :class="getIconsColor()"></i>
            </span>
            <span class="title">Abrir solicitação de suprimento</span>
          </a>
        </li>
        <!-- <li class="nav-item"><a class="sidebar-link" href="{% url 'core:users-list' %}"><span class="icon-holder"><i class="c-red-500 ti-user"></i>
                </span><span class="title">Usuários</span></a></li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import ticketService from "@/services/tickets";
import auth_service from "@/services/auth";
import config from "@/config";

export default {
  name: "CcSidebar",
  data() {
    return {
      open_count: 0,
      ongoing_count: 0,
      test_count: 0,
      user: auth_service.getUserInformation(),
      config,
    };
  },
  computed: {
    active_tickets_list() {
      return this.$router.currentRoute.name == "CcMain";
    },
    active_tickets_ongoing() {
      return this.$router.currentRoute.name == "CcOngoing";
    },
    active_tickets_history() {
      return this.$router.currentRoute.name == "CcHistory";
    },
    active_tickets_test() {
      return this.$router.currentRoute.name == "CcTest";
    },
  },
  methods: {
    fetchData() {
      ticketService.count({ status: 0 }, this.addCountsToScope);
      ticketService.count({ status: 1 }, this.addCountsToScope);
      ticketService.count({ test: 1 }, this.addTestCountToScope);
    },
    getIconsColor() {
      return {
        "c-red-500": config.serverUrl === "chamados.maq-larem.com.br",
        "c-blue-500": config.serverUrl !== "chamados.maq-larem.com.br",
      };
    },
    addCountsToScope(data) {
      if (data.data.status == 0) {
        this.open_count = data.data.count;
      } else {
        this.ongoing_count = data.data.count;
      }
    },
    addTestCountToScope(data) {
      this.test_count = data.data.count;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
