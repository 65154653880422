export default {
    serverUrl: "chamados.alfaprintpb.com.br",
    serverPort: "443"
}

// export default {
//     serverUrl: "chamados.maq-larem.com.br",
//     serverPort: "80"
// }

// export default {
//     serverUrl: "localhost",
//     serverPort: "8080"
// }