import Vue from 'vue';
import VueRouter from 'vue-router'
import CcMain from '@/views/CcMain';
import CcLogin from '@/views/CcLogin';
import CcOngoing from '@/views/CcOngoing';
import CcHistory from '@/views/CcHistory';
import CcDashboard from '@/views/CcDashboard';
import CcAudit from '@/views/CcAudit';
import CcSLA from '@/views/CcSLA';
import CcNewTicket from '@/views/CcNewTicket';
import CcDashboardState from '@/views/CcDashboardState';
import CcTest from '@/views/CcTest';
import AuthService from "@/services/auth.js";

Vue.use(require('vue-cookies'));
Vue.use(VueRouter);

let router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'CcMain',
      component: CcMain
    },
    {
      path: '/login',
      name: 'CcLogin',
      component: CcLogin
    },
    {
      path: '/em_andamento',
      name: 'CcOngoing',
      component: CcOngoing
    },
    {
      path: '/test',
      name: 'CcTest',
      component: CcTest
    },
    {
      path: '/history',
      name: 'CcHistory',
      component: CcHistory
    },
    {
      path: '/dashboard',
      name: 'CcDashboard',
      component: CcDashboard
    },
    {
      path: '/tabela',
      name: 'CcSLA',
      component: CcSLA
    },
    {
      path: '/audit',
      name: 'CcAudit',
      component: CcAudit
    },
    {
      path: '/dashboard/per_state',
      name: 'CcDashboardState',
      component: CcDashboardState
    },
    {
      path: '/novo_chamado',
      name: 'CcNewTicket',
      component: CcNewTicket
    }
  ]
});

router.beforeEach((to, from, next) => {
  let user_info = AuthService.getUserInformation();

  if (user_info.id == null && to.name != 'CcLogin') {
    next("/login");
  } else {
    // Percorre os components daquela rota para carregar o titulo da pagina
    next();
  }
});

export default router;