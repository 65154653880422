<template>
    <div id="app">
        <CcSidebar></CcSidebar>
        <div class="page-container">
            <CcHeader></CcHeader>
            <main class="main-content bgc-grey-100">
                <div id="mainContent">
                    <div class="full-container">
                        <div class="email-app">
                            <div class="row mR-0">
                                <div class="col-md-9">
                                    <div class="card m-10" style="height: calc(100% - 20px);">
                                        <div
                                            class="card-header"
                                        >Chamados do dia separados por tecnico</div>
                                        <div class="card-body">
                                            <div class="chart-wrapper" style="position: relative;">
                                                <canvas id="Chart_technicians"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="height: 100%">
                                    <div class="card mT-10 mB-10">
                                        <div class="card-header">
                                            Chamados por Estado
                                            <router-link
                                                class="sidebar-link"
                                                :to="{ name: 'CcDashboardState' }"
                                            >
                                                <span class="title">[+]</span>
                                            </router-link>
                                        </div>
                                        <div class="card-body">
                                            <div class="chart-wrapper" style="position:relative">
                                                <canvas id="Chart_state"></canvas>
                                            </div>
                                        </div>
                                        <div class="card-header">Chamados por Tipo</div>
                                        <div class="card-body">
                                            <div class="chart-wrapper" style="position:relative">
                                                <canvas id="Chart_type"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <CcFooter></CcFooter>
        </div>
    </div>
</template>

<script>
import utils from "@/services/utils";
import ticketService from "@/services/tickets";
import AuthService from "@/services/auth.js";

import CcSidebar from "@/components/CcSidebar.vue";
import CcHeader from "@/components/CcHeader.vue";
import CcFooter from "@/components/CcFooter.vue";

export default {
    name: "CcMain",
    components: {
        CcSidebar,
        CcHeader,
        CcFooter
    },
    data() {
        return {};
    },
    methods: {
        getJson(data) {
            window.localStorage.setItem("chart_data", JSON.stringify(data));

            var ctx_type = utils.get_element_or_undefined("Chart_type");
            var ctx_state = utils.get_element_or_undefined("Chart_state");
            var ctx_technicians = utils.get_element_or_undefined(
                "Chart_technicians"
            );

            if (ctx_state) {
                this.createChart(
                    ctx_state,
                    data["counts_per_state"],
                    "state_chart"
                );
            }
            if (ctx_type) {
                this.createChart(
                    ctx_type,
                    data["counts_per_type"],
                    "type_chart"
                );
            }
            if (ctx_technicians) {
                this.createChart(
                    ctx_technicians,
                    data["counts_per_technician"],
                    "technicians_chart"
                );
            }
        },
        createChart(ctx, data, chart_var) {
            /*global Chart */
            var datasets = [
                {
                    data: [],
                    backgroundColor: [],
                    borderColor: []
                }
            ];

            let labels = [];
            var counting = 0;

            for (const i of Object.keys(data)) {
                labels.push(i);
                const element = data[i]["counts_per_days"];
                for (let k = 0; k < element.length; k++) {
                    const value = element[k][0];

                    datasets[0].data.push(value);

                    let color = "#ffeb3b";
                    switch (counting % 4) {
                        case 1:
                            color = "#2196f3";
                            break;
                        case 2:
                            color = "#f44336";
                            break;
                        case 3:
                            color = "#4caf50";
                            break;
                    }
                    counting++;

                    datasets[0].backgroundColor.push(color);
                    datasets[0].borderColor.push(color);
                }
            }

            window[chart_var] = new Chart(ctx, {
                type:
                    chart_var == "technicians_chart" ? "horizontalBar" : "bar",
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    plugins: {
                        // Change options for ALL labels of THIS CHART
                        datalabels: {
                            anchor: "end",
                            backgroundColor: "rgba(255,255,255,0.95)",
                            borderRadius: 20,
                            font: {
                                family:
                                    "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                                size: "10",
                                color: "black"
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: chart_var == "technicians_chart" ? 0.3 : 2,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    autoSkip: false,
                                    beginAtZero: true
                                }
                            }
                        ],
                        xAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }
                        ]
                    },
                    animation: {
                        duration: 0,
                        onComplete: function() {
                            // render the value of the chart above the bar
                            var ctx = this.chart.ctx;
                            ctx.font = Chart.helpers.fontString(
                                Chart.defaults.global.defaultFontSize,
                                "normal",
                                Chart.defaults.global.defaultFontFamily
                            );
                            console.log(Chart.defaults.global.defaultFontSize);
                            // ctx.fillStyle = this.chart.config.options.defaultFontColor;
                            ctx.fillStyle = "#000000";
                            ctx.textAlign = "center";
                            ctx.textBaseline = "bottom";
                            this.data.datasets.forEach(function(dataset) {
                                for (var i = 0; i < dataset.data.length; i++) {
                                    var model =
                                        dataset._meta[
                                            Object.keys(dataset._meta)[0]
                                        ].data[i]._model;
                                    ctx.fillText(
                                        dataset.data[i],
                                        chart_var == "technicians_chart"
                                            ? model.x - 5
                                            : model.x,
                                        chart_var == "technicians_chart"
                                            ? model.y + 8
                                            : model.y
                                    );
                                }
                            });
                        }
                    }
                }
            });
        }
    },
    created() {
        let user_info = AuthService.getUserInformation();

        if (user_info.id == null) {
            this.$router.push("/login");
        }
        ticketService.dashboard(this.getJson);
    },
    filters: {},
    watch: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scrollable {
    overflow-y: scroll;
}
</style>
