<template>
    <div id="app">
        <CcSidebar></CcSidebar>
        <div class="page-container">
            <CcHeader @search="search"></CcHeader>
            <main class="main-content bgc-grey-100">
                <div id="mainContent">
                    <div class="full-container">
                        <div class="email-app">
                            <div class="email-wrapper row remain-height bgc-white ov-h">
                                <div class="email-list h-100 layers">
                                    <div class="layer w-100">
                                        <div class="bgc-grey-100 peers ai-c jc-sb fxw-nw">
                                            <div class="peer w-50">
                                                <div class="layers bgc-green-50 p-20">
                                                    <div class="layer w-100">
                                                        <h6 class="lh-1">Total concluidos</h6>
                                                    </div>
                                                    <div class="layer w-100">
                                                        <div class="peers ai-sb fxw-nw">
                                                            <div class="peer peer-greed">
                                                                <span id="sparklinedash">
                                                                    <canvas
                                                                        width="45"
                                                                        height="20"
                                                                        style="display: inline-block; width: 45px; height: 20px; vertical-align: top;"
                                                                    ></canvas>
                                                                </span>
                                                            </div>
                                                            <div class="peer">
                                                                <span
                                                                    class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-green-500 c-white"
                                                                >{{ concl_count }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="peer w-50">
                                                <div class="layers bgc-red-50 p-20">
                                                    <div class="layer w-100">
                                                        <h6 class="lh-1">Total negados</h6>
                                                    </div>
                                                    <div class="layer w-100">
                                                        <div class="peers ai-sb fxw-nw">
                                                            <div class="peer peer-greed">
                                                                <span id="sparklinedash4">
                                                                    <canvas
                                                                        width="45"
                                                                        height="20"
                                                                        style="display: inline-block; width: 45px; height: 20px; vertical-align: top;"
                                                                    ></canvas>
                                                                </span>
                                                            </div>
                                                            <div class="peer">
                                                                <span
                                                                    class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-red-500 c-white"
                                                                >{{ denied_count }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="layer w-100">
                                        <div class="bgc-grey-100 peers ai-c jc-sb p-20 fxw-nw">
                                            <div class="peer">
                                                <div class="btn-group" role="group">
                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.group_by == 0 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('group_by', 0)"
                                                    >
                                                        <i class="ti-hummer"></i>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.group_by == 1 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('group_by', 1)"
                                                    >
                                                        <i class="ti-paint-bucket"></i>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.group_by == 2 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('group_by', 2)"
                                                    >
                                                        <i class="ti-info"></i>
                                                    </button>


                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.group_by == 3 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('group_by', 3)"
                                                    >
                                                        <i class="ti-desktop"></i>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        class="btn bgc-white bdrs-2 mR-3 cur-p"
                                                        v-on:click="toggleParams('group_by', -1)"
                                                    >
                                                        <i class="ti-close"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="peer">
                                                <div class="btn-group" role="group">
                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.status == 3 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('status', 3)"
                                                    >
                                                        <i class="ti-thumb-up"></i>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        class="btn bdrs-2 mR-3 cur-p"
                                                        v-bind:class="[params.status == 2 ? 'bgc-grey-300' : 'bgc-white']"
                                                        v-on:click="toggleParams('status', 2)"
                                                    >
                                                        <i class="ti-thumb-down"></i>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        class="btn bgc-white bdrs-2 mR-3 cur-p"
                                                        v-on:click="toggleParams('status', [2,3])"
                                                    >
                                                        <i class="ti-close"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="peer">
                                                <div class="btn-group" role="group">
                                                    <div v-for="state in states" :key="state.code">
                                                        <button
                                                            type="button"
                                                            class="btn bdrs-2 mR-3 cur-p"
                                                            v-on:click="toggleParams('state', state.code)"
                                                            v-bind:class="[state.code == params.state ? 'bgc-grey-300' : 'bgc-white']"
                                                        >{{state.code}}</button>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        class="btn bgc-white bdrs-2 mR-3 cur-p"
                                                        v-on:click="toggleParams('state', -1)"
                                                    >
                                                        <i class="ti-close"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="layer w-100 fxg-1 scrollable pos-r"
                                        id="tickets_list_div"
                                    >
                                        <div class>
                                            <div v-if="loading.tickets == false">
                                                <div
                                                    class="email-list-item peers fxw-nw p-20 bdB bgcH-grey-100 cur-p"
                                                    v-on:click="open_if_not_already_open(ticket.id)"
                                                    v-for="ticket in tickets"
                                                    :key="ticket.id"
                                                >
                                                    <div class="peer peer-greed ov-h">
                                                        <div class="peers ai-c">
                                                            <div class="peer peer-greed">
                                                                <span
                                                                    class="badge badge-pill badge-success lh-0 p-10"
                                                                    v-if="ticket.status == 3"
                                                                >concl.</span>
    
                                                                <span
                                                                    class="badge badge-pill badge-warning lh-0 p-10"
                                                                    v-else-if="ticket.status == 1"
                                                                >databit</span>
    
                                                                <span
                                                                    class="badge badge-pill badge-danger lh-0 p-10"
                                                                    v-else
                                                                >neg</span>
    
                                                                <span
                                                                    class="badge badge-pill badge-primary lh-0 p-10"
                                                                    v-if="ticket.type == 0"
                                                                >man</span>
    
                                                                <span
                                                                    class="badge badge-pill badge-warning lh-0 p-10"
                                                                    v-else-if="ticket.type == 1"
                                                                >sup</span>
    
                                                                <span
                                                                    class="badge badge-pill badge-secondary lh-0 p-10"
                                                                    v-else-if="ticket.type == 2"
                                                                >ler</span>
    
                                                                <span
                                                                    class="badge badge-pill badge-info lh-0 p-10"
                                                                    v-else-if="ticket.type == 3"
                                                                >sol</span>
    
    
                                                                <div v-if="ticket.client_obj">
                                                                    <span
                                                                        class="fsz-def c-grey-900 mt-2"
                                                                    >
                                                                        <b>{{ ticket.client_obj.client }}</b>
                                                                        <small class="c-grey-700">
                                                                            ({{ticket.code}}) por
                                                                            {{ ticket.client_obj.name }}
                                                                        </small>
                                                                    </span>
                                                                </div>
                                                                <!-- add fallback to client_obj -->
                                                                <div v-else>
                                                                    <span
                                                                        class="fsz-def c-grey-900 mt-2"
                                                                    >
                                                                        <b>{{ ticket.client }}</b>
                                                                        <small class="c-grey-700">
                                                                            ({{ticket.code}}) por
                                                                            {{ ticket.name }}
                                                                        </small>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="peer">
                                                                <small>{{ ticket.created_at| date}}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-100 mt-1" style="text-align:center">
                                                <div v-if="loading.tickets">Carregando...</div>
                                                <button
                                                    class="btn btn-sm btn-outline-primary load-more"
                                                    @click="load_more()"
                                                    v-else-if="tickets.length>=100"
                                                >Carregar Mais</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="email-content h-100" v-if="ticket">
                                    <div class="h-100 scrollable pos-r">
                                        <div class="email-content-wrapper">
                                            <!-- Header -->
                                            <div class="peers ai-c jc-sb p-10">
                                                <div class="peers peer-greed">
                                                    <div class="peer w-100">
                                                        <span
                                                            class="badge badge-pill badge-success lh-0 p-10"
                                                            v-if="ticket.status == 3"
                                                        >concluido</span>

                                                        <span
                                                            class="badge badge-pill badge-warning lh-0 p-10"
                                                            v-else-if="ticket.status == 1"
                                                        >No databit</span>

                                                        <span
                                                            class="badge badge-pill badge-danger lh-0 p-10"
                                                            v-else
                                                        >negado</span>

                                                        <span
                                                            class="badge badge-pill badge-primary lh-0 p-10"
                                                            v-if="ticket.type == 0"
                                                        >man</span>

                                                        <span
                                                            class="badge badge-pill badge-warning lh-0 p-10"
                                                            v-else-if="ticket.type == 1"
                                                        >sup</span>

                                                        <span
                                                            class="badge badge-pill badge-secondary lh-0 p-10"
                                                            v-else-if="ticket.type == 2"
                                                        >ler</span>

                                                        <span
                                                            class="badge badge-pill badge-info lh-0 p-10"
                                                            v-else-if="ticket.type == 3"
                                                        >sol</span>

                                                        <span>numero do chamado: {{ticket.code}} numero do chamado no databit: {{ticket.code_databit|default_if_none("Nenhum")}}</span>
                                                        <small
                                                            class="fl-r"
                                                        >{{ ticket.created_at|date }}</small>
                                                        <h5
                                                            class="c-grey-900 mB-5 mt-2"
                                                        >{{ ticket.client_obj.client }}</h5>
                                                        <p class="c-grey-900 mB-2">
                                                            <span>
                                                                Solicitante: {{
                                                                ticket.client_obj.name }}
                                                            </span>
                                                        </p>
                                                        <p class="c-grey-900 mB-2">
                                                            Descrição do problema: {{
                                                            ticket.description }}
                                                        </p>
                                                        <template v-if="ticket.toner_colors != {}">
                                                            <p
                                                                class="c-grey-900 mB-2"
                                                                v-for="color in ticket.toner_colors"
                                                                v-bind="color"
                                                                :key="color.color"
                                                            >Cor do toner: {{color.color}} : {{color.qtd}}</p>
                                                        </template>


                                                        <p class="c-grey-900 mB-2">
                                                            E-mail:{{ ticket.client_obj.email }}
                                                            <span
                                                                style="margin-left:10rem"
                                                            >
                                                                Telefone: {{
                                                                ticket.client_obj.phone }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Content -->
                                            <div class="bdT pX-20 pY-20">
                                                <div>
                                                    <h5 class="c-grey-900 mB-5">Equipamento</h5>
                                                    <h6>Matrícula: {{ ticket.registration|default_if_none("Não informado")}}</h6>
                                                    <h6>Modelo: {{ ticket.model|default_if_none("Não informado")}}</h6>
                                                    <h6>Contador: {{ ticket.counter|default_if_none("Não informado")}}</h6>
                                                </div>
                                                <div>
                                                    <h5 class="c-grey-900 mB-5">Contato</h5>
                                                    <h6>Setor: {{ ticket.sector|default_if_none("Não informado")}}</h6>
                                                    <h6>Endereço: {{ ticket.address|default_if_none("Não informado")}}</h6>
                                                    <h6>Telefone: {{ ticket.phone|default_if_none("Não informado")}}</h6>
                                                </div>
                                                <div>
                                                    <h5 class="c-grey-900 mB-5">Suporte</h5>
                                                    <h6>Técnico: {{ ticket.technician|default_if_none("Não informado")}}</h6>
                                                    <h6>OBS Suporte: {{ ticket.obs|default_if_none("Não informado") }}</h6>
                                                </div>
                                            </div>
                                            <!-- Content -->
                                            <div class="bdT pX-20 pY-20">
                                                <div>
                                                    <h5 class="c-grey-900 mB-5">Status</h5>

                                                    <h6
                                                        v-if="ticket.status == 1 || ticket.status == 3"
                                                    >Aprovado por {{ ticket.user|default_if_none('Sistema') }}, em {{ ticket.updated_at|date:'d/m/Y H:i' }}.</h6>
                                                    <h6
                                                        v-else
                                                    >Negado por {{ ticket.user }}, em {{ ticket.updated_at|date }}.</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <CcFooter></CcFooter>
        </div>
    </div>
</template>

<script>
import technicianService from "@/services/technicians.js";
import AuthService from "@/services/auth.js";
import ticketService from "@/services/tickets";
import statesService from "@/services/states";

import CcSidebar from "@/components/CcSidebar.vue";
import CcHeader from "@/components/CcHeader.vue";
import CcFooter from "@/components/CcFooter.vue";

import utils from "@/services/utils.js";

export default {
    name: "CcMain",
    components: {
        CcSidebar,
        CcHeader,
        CcFooter,
    },
    data() {
        return {
            states: [],
            selected_state: "",
            form: {
                obs: ""
            },
            ticket: false,
            tickets: [],
            technicians: [
                {
                    name: "",
                    pk: ""
                }
            ],
            params: {
                status: [2, 3],
                limit: 100,
                offset: 0
            },
            concl_count: 0,
            denied_count: 0,
            loading: {
                tickets: true
            }
        };
    },
    methods: {
        fetchData() {
            technicianService.all(this.addTechniciansToScope);
            statesService.all(this.addStatesToScope);
            this.update_tickets();
        },
        addTechniciansToScope(data) {
            this.technicians = [];
            data.forEach(technician => {
                this.technicians.push({
                    name: "{0} ({1})"
                        .replace("{0}", technician.name)
                        .replace("{1}", technician.count),
                    pk: technician.id
                });
            });
        },
        addTicketsToScope(data) {
            this.tickets = [];

            data.data.tickets.forEach(ticket => {
                this.tickets.push(ticket);
            });

            this.ticket = this.tickets[0];
            this.loading.tickets = false;
        },
        addCountsToScope(data) {
            if (data.data.status == 2) {
                this.denied_count = data.data.count;
            } else {
                this.concl_count = data.data.count;
            }
        },
        addStatesToScope(data) {
            this.states = [];
            data.forEach(state => {
                this.states.push(state);
            });
        },
        toggleParams(key, value) {
            if (value == -1) {
                this.$delete(this.params, key);
            } else {
                this.$set(this.params, key, value);
            }
        },
        open_if_not_already_open(ticket_id) {
            this.tickets.forEach(ticket => {
                if (ticket.id == ticket_id) {
                    this.ticket = ticket;
                }
            });
        },
        load_more() {
            this.params.limit += 100;
            this.update_tickets();
        },
        update_tickets() {
            this.loading.tickets = true;
            ticketService.query(this.params, this.addTicketsToScope);
            ticketService.count(
                Object.assign({}, this.params, { status: 2 }),
                this.addCountsToScope
            );
            ticketService.count(
                Object.assign({}, this.params, { status: 3 }),
                this.addCountsToScope
            );
        },
        search(data) {
            this.loading.tickets = true;
            this.params.search = data;
            if (this.params.search == "") {
                delete this.params.search;
            }
            ticketService.query(this.params, this.addTicketsToScope);
        }
    },
    created() {
        let user_info = AuthService.getUserInformation();

        if (user_info.id == null) {
            this.$router.push("/login");
        } else {
            this.fetchData();
        }
    },
    filters: {
        date: utils.format_date,
        default_if_none: function(value, arg) {
            if (!value) {
                return arg;
            }
            return value;
        }
    },
    watch: {
        params: {
            handler: function() {
                this.update_tickets();
            },
            deep: true
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
    font-size: 0.7rem !important;
}

h1,
h2 {
    font-weight: normal;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
#tickets_list_div {
    overflow-y: scroll !important;
}

.load-more {
    border-radius: 1rem;
    width: 100px;
}

.scrollable {
    overflow-y: scroll;
}
</style>
