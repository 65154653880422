import config from "@/config.js"
import axios from 'axios'

export default class {

    constructor(url) {
        this.url = "https://{0}:{1}/{2}".replace("{0}", config.serverUrl).replace("{1}", config.serverPort).replace("{2}", url);
        if(!this.url.endsWith("/")){
            this.url += "/"
        }
    }

    all(callback) {
        axios.get(this.url).then((resp) => {
            callback(resp.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    find(data,callback) {
        axios.get(this.url, {params:data}).then((resp) => {
            callback(resp.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    get(id, callback) {
        axios.get(`${this.url}${id}/`).then((resp) => {
            callback(resp.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    update(data, callback){
        axios.post(this.url, data).then((resp) => {
            callback(resp.data)
        }).catch((err) => {
            console.log(err)
        })
    }
}