<template>
    <div id="app">
        <CcSidebar></CcSidebar>
        <div class="page-container">
            <CcHeader></CcHeader>
            <main class="main-content bgc-grey-100">
                <div id="mainContent">
                    <div class="full-container">
                        <div class="row mR-0">
                            <div class="col-md-9">
                                <div class="card m-10" style="height: calc(100% - 20px);">
                                    <div class="card-header">
                                        Chamados separados por estado
                                        <div class="pull-right">
                                            <select v-model="month">
                                                <option disabled value>Escolha um item</option>
                                                <option value="1">Janeiro</option>
                                                <option value="2">Fevereiro</option>
                                                <option value="3">Março</option>
                                                <option value="4">Abril</option>
                                                <option value="5">Maio</option>
                                                <option value="6">Junho</option>
                                                <option value="7">Julho</option>
                                                <option value="8">Agosto</option>
                                                <option value="9">Setembro</option>
                                                <option value="10">Outubro</option>
                                                <option value="11">Novembro</option>
                                                <option value="12">Dezembro</option>
                                            </select>
                                            <select v-model="year">
                                                <option disabled value>Escolha um item</option>
                                                <option
                                                    :value="current_year"
                                                    v-for="current_year in available_years"
                                                    :key="current_year"
                                                >{{current_year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <table class="table">
                                            <tr>
                                                <th>Estado</th>
                                                <th>Chamados</th>
                                                <th>Requisições</th>
                                                <th>Leituras</th>
                                                <th>Total</th>
                                            </tr>
                                            <tr v-for="(item, key, index) in tickets_data.estados" :key="index">
                                                <td>{{key}}</td>
                                                <td>{{item.Chamados}}</td>
                                                <td>{{item.Requisições}}</td>
                                                <td>{{item.Leituras}}</td>
                                                <td>{{item.Total}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card m-10">
                                    <div class="card-header">Totais</div>
                                    <div class="card-body">
                                        <p>Total: {{tickets_data.total}}</p>
                                        <p>Total chamados: {{tickets_data.total_chamados}}</p>
                                        <p>Total requisições: {{tickets_data.total_requisições}}</p>
                                        <p>Total leituras: {{tickets_data.total_leituras}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <CcFooter></CcFooter>
        </div>
    </div>
</template>

<script>
import data_tickets_service from "@/services/data_tickets.js";

import CcSidebar from "@/components/CcSidebar.vue";
import CcHeader from "@/components/CcHeader.vue";
import CcFooter from "@/components/CcFooter.vue";

export default {
    name: "CcMain",
    components: {
        CcSidebar,
        CcHeader,
        CcFooter
    },
    data() {
        let date = new Date();
        return {
            // month: date.getMonth(),
            month: date.getMonth(),

            year: date.getFullYear(),
            tickets_data: {}
        };
    },
    methods: {
        data_tickets_callback(data) {
            let formated_data = {};
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    let new_key = key.toLocaleLowerCase().replace(" ", "_");
                    formated_data[new_key] = element;
                }
            }
            
            this.tickets_data = formated_data;
        },
        refresh() {
            data_tickets_service.get_data(
                this.month,
                this.year,
                this.data_tickets_callback
            );
        }
    },
    created() {
        this.refresh();
    },
    filters: {},
    watch: {
        month() {
            data_tickets_service.get_data(
                this.month,
                this.year,
                this.data_tickets_callback
            );
        },
        year() {
            data_tickets_service.get_data(
                this.month,
                this.year,
                this.data_tickets_callback
            );
        }
    },
    computed: {
        available_years() {
            let date = new Date();
            let year = 2016;
            let years = [];

            for (let index = year; index <= date.getFullYear(); index++) {
                years.push(index);
            }

            return years;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scrollable {
    overflow-y: scroll;
}
</style>
